<template>
    <div class="d-flex">
      <div class="mt-4">
        <v-avatar v-if="imageData" size="64px" color="grey">
          <img :src="imageData" alt="Your profile picture">
        </v-avatar>
        <v-avatar v-else size="64px" color="grey" @click="uploadPhoto">
          <v-icon color="white">fa-plus</v-icon>
        </v-avatar>
      </div>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-form ref="fileUploadForm" class="d-flex w-100p">
          <!-- <v-btn text @click="uploadPhoto"><v-icon class="me-1" small>fa-camera</v-icon> Upload Photo</v-btn> -->
            <v-file-input ref="uploadFileInput" class="d-flex ms-4" style="" hide-details hide-inputZ solo width="200px" prepend-icon="fa fa-camera" accept="image/*" :rules="rules" :label="$t('profile.edit.select-profile-image')" v-model="file" v-on:change="updatePreview"></v-file-input>
          </v-form>
        </v-col>
        <v-col>
          <v-btn v-if="imageData && !previewMode" text @click="clearProfilePicture" class="" :loading="deletingAvatar"><v-icon class="me-1" small>fa-times</v-icon> Clear</v-btn>
          <v-alert v-if="syncResult && syncResult.status == 'OK'" type="success">{{$t('profile.edit.picture-saved-successfully')}}</v-alert>
          <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info"><strong>{{ syncResult.msg }}</strong></v-alert>
          <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
          <v-alert v-if="previewMode" type="info">Preview of how your avatar will look, don't worry if it looks stretched, this will be fixed when you save your avatar.</v-alert>
          <v-btn v-if="previewMode" color="success" @click="uploadFile" :loading="$store.getters.isLoading">{{ $t('shared.save') }}</v-btn>
          <v-btn v-if="previewMode && !$store.getters.isLoading" text @click="cancelUpload"><v-icon class="me-1" small>fa-times</v-icon> {{ $t('shared.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </div>
</template>

<script>

import profileService from "@/services/profileService";

export default {
  name: "AvatarUploadForm",
  props: {
    img: String,
  },
  data: function() {
    return {
      file: null,
      previewMode: false,
      previewImgData: null,
      syncResult: null,
      deletingAvatar: false,
      rules: [
        value => !value || value.size < 5000000 || 'Avatar size should be less than 5 MB!',
      ]
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async clearProfilePicture() {
      if (confirm('Are you sure you want to delete your current profile picture?')) {
        this.deletingAvatar = true;
        var response = await profileService.deleteAvatar();
        this.deletingAvatar = false;
        this.syncResult = response.data;
        console.log('// udpate avatar results', response.data);
        if (this.syncResult.status == "OK") {
          this.$emit('change');
        }
      }
    },

    async cancelUpload() {
      this.previewMode = false;
      this.syncResult = null;
      this.previewImgData = null;
      this.$refs.fileUploadForm.reset();
    },

    async uploadPhoto() {
      console.log(this.$refs.uploadFileInput);
      this.$refs.uploadFileInput.input.click();
    },

    async updatePreview() {
      if (this.file) {
        this.syncResult = null;
        this.previewImgData = URL.createObjectURL(this.file);
        this.previewMode = true;
      }
    },
    async uploadFile() {
      this.syncResult = null;
      if (this.file) {
        this.previewImgData = URL.createObjectURL(this.file);
        let formData = new FormData()
        formData.append("file", this.file, this.file.name);
        var response = await profileService.uploadAvatar(formData);
        this.syncResult = response.data;
        if (this.syncResult.status == "OK") {
          this.previewMode = false;
          this.$emit('change');
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
    imageData() {
      return this.previewImgData || this.img;
    }
  },
};
</script>

<style scoped>
</style>